import React from 'react'
import { graphql } from 'gatsby'
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FacebookIcon from "../svg/facebook.svg"
import LinkedinIcon from "../svg/linkedin.svg"
import GithubIcon from "../svg/github.svg"

export const AboutPageTemplate = ({ image, title, description }) => {
  return (
    <section className="section mt--1">
      <div className="container">
        <div className="columns">
          <Image
            fluid={image}
            alt="andrew"
            style={{
              maxWidth: "300px",
              maxHeight: "300px",
              margin: "auto"
            }}
            imgStyle={{
              borderRadius: `100%`,
              maxWidth: "300px",
              maxHeight: "300px"
            }}
          />
          <div className="pt--2 text--center">
            <h2 className="mb--0-5">{title}</h2>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

const socialMedia = [
  {alt: "linkedin", url: "https://www.linkedin.com/in/andreeew/", component: <LinkedinIcon style={{paddingBottom: "5px"}}/>},
  {alt: "github", url: "https://github.com/redXawn", component: <GithubIcon style={{paddingBottom: "5px"}}/>},
  {alt: "facebook", url: "https://www.facebook.com/Andrew.Ew565/", component: <FacebookIcon style={{paddingBottom: "5px"}}/>}
]

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <SEO title="About" />
      <AboutPageTemplate
        image={data.avatar.childImageSharp.fluid}
        title={frontmatter.title}
        description={frontmatter.description}
      />
      <div className="d--flex j--center">
        {socialMedia.map((item, index) => {
            return (
              <a href={item.url} target="blank" className="m--1" key={index}>
                <div className="about-icon">
                  { item.component}
                </div>
              </a>
            )
          }
        )}
      </div>
    </Layout>
  )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query GetAbout {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 2048, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
      frontmatter {
        title
        description
      }
    }
  }
`
